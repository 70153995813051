import { Component, Input, OnInit } from "@angular/core";
import { FieldConfigTimeDto } from "connect-frontend-service";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "lib-field-time",
  templateUrl: "./field-time.component.html",
  styleUrls: ["./field-time.component.scss"]
})
export class FieldTimeComponent implements OnInit {

  @Input()
  public config: FieldConfigTimeDto;

  @Input()
  public formGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
