import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { ClearIconComponentResetInputComponent } from "./clear-icon/clear-icon-component-reset-input.component";
import { ResetInputDirective } from "./reset-input.directive";

@NgModule({
  imports: [ MatIconModule ],
  declarations: [ ResetInputDirective, ClearIconComponentResetInputComponent ],
  exports: [ ResetInputDirective ]
})
export class ResetInputModule {}
