import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Observable } from "rxjs";
import {
  AbstractAkteEditDto,
  AkteControllerClient,
  AkteCreateForwardLightDto,
  AkteEditEmailDto,
  AkteEditNotizDto,
  AkteEintragTypRichtungDto,
  AkteListePagedRequestDto,
  AktenAnhangDto,
  AktenAnhangEditDto,
  AktenEintragAnhaengeZipDownloadRequestDto,
  AktenEintragDto,
  AktenListeDto,
  AktenTypDto,
  AktePermissionsDto,
  AkteRefDto,
  AnhaengeZipDownloadRequestDto,
  EmailAblegenDto,
  EmailErstellenDto,
  ExternalUserWithAccessDto,
  LightAufgabeDto,
  ListeKundeOptionsDto,
  MaklernachrichtAdressenRequestDto,
  MaklernachrichtEmpfaengerDto,
  MaklernachrichtErstellenDto,
  NotizErstellenDto,
  SchadenmeldungErgebnisDto
} from "../dto-interfaces/connect-webservice";

import { FormDataBuilder } from "./form-data-builder";
import { HttpRestAdapterService } from "./http-rest-adapter.service";

type AktenEintragTyp = "briefentwurf" | "brief" | "email" | "maklernachricht" | "notiz"  | "schlagworte";

export abstract class AktenEintragBuilder<T> {
  protected version = 0;
  protected betreff = "";
  protected von = "";
  protected an = "";
  protected nachricht = "";
  protected forwards = [];
  protected schlagworte: string[] = [];
  protected akteRefs: AkteRefDto[] = [];
  protected files: [] = [];

  protected constructor() {
    // This is intentional
  }

  withVon(von: string) {
    this.von = von;
    return this.yourself();
  }

  withAn(an: string) {
    this.an = an;
    return this.yourself();
  }

  withBetreff(betreff: string) {
    this.betreff = betreff;
    return this.yourself();
  }

  withNachricht(nachricht: string) {
    this.nachricht = nachricht;
    return this.yourself();
  }

  withAkteRefs(refs: AkteRefDto[]) {
    this.akteRefs = [...refs];
    return this.yourself();
  }

  withFiles(files: []) {
    this.files = [...files];
    return this.yourself();
  }

  withForwards(forwards: []) {
    this.forwards = [...forwards];
    return this.yourself();
  }

  withSchlagworte(schlagworte: string[]) {
    this.schlagworte = [...schlagworte];
    return this.yourself();
  }

  protected abstract build(): T;
  protected abstract yourself();
}

export class NotizBuilder extends AktenEintragBuilder<NotizErstellenDto> {
  private editingType = "notiz" as const;
  private richtungType: AkteEintragTypRichtungDto;
  private datum: string;
  private anId: number;

  constructor() {
    super();
  }

  public beAusgehend() {
    this.richtungType = AkteEintragTypRichtungDto.AUSGEHEND;
    return this.yourself();
  }

  public beEingehend() {
    this.richtungType = AkteEintragTypRichtungDto.EINGEHEND;
    return this.yourself();
  }

  public withDatum(datum: string) {
    this.datum = datum;
    return this.yourself();
  }

  build(): NotizErstellenDto {
    const create: AkteEditNotizDto = {
      version: this.version,
      editingType: this.editingType,
      typ: this.richtungType,
      von: this.von,
      an: { id: this.anId, name: this.an, email: "" },
      datum: this.datum,
      betreff: this.betreff,
      nachricht: this.nachricht,
      akteRefs: this.akteRefs,
      forward: this.forwards,
      schlagworte: this.schlagworte,
      files: this.files,
    };
    return {
      akteRefs: this.akteRefs,
      edit: create,
      termin: null
    } as NotizErstellenDto;
  }

  protected yourself() {
    return this;
  }
}

@Injectable({
  providedIn: "root",
})
export class ConnectFrontendAkteService extends AkteControllerClient {

  private readonly akteUrl: string;

  /** A HTTPClient instance (not an adapted one {@link HttpRestAdapterService}) */
  private readonly httpRestClient: HttpClient;
  constructor(
    httpClient: HttpClient,
    httpRestAdapter: HttpRestAdapterService,
    @Inject("apiUrl")
    private readonly apiUrl: string,
    private translateService: TranslateService,
  ) {
    /**
     * <b>Implementation note</b>
     * <br/>The super() call assigns an adapted version of HttpClient (httpRestAdapter) to the protected httpClient variable
     * declared in the super class. This means that the original httpClient implementation is overwritten with the
     * adapted version. In order to keep a reference to the original one we need an extra private variable
     * (httpRestClient) which we can initialize separately.
     */
    super(httpRestAdapter);
    this.akteUrl = `${this.apiUrl}internal/ui/akte`;
    this.httpRestClient = httpClient;
  }

  public downloadAllAnhaengeAsZip(request: AnhaengeZipDownloadRequestDto): Observable<Blob> {
    return this.httpRestClient.post(`${this.akteUrl}/anhaenge`, request, { responseType: "blob" });
  }

  public downloadSingleAnhangAsZip(request: AktenEintragAnhaengeZipDownloadRequestDto): Observable<Blob> {
    return this.httpRestClient.post(`${this.akteUrl}/anhang`, request, { responseType: "blob" });
  }

  public getAkteKundeUsers(): Observable<ListeKundeOptionsDto[]> {
    return this.httpRestClient.get<ListeKundeOptionsDto[]>(`${this.apiUrl}internal/ui/akte/liste/kunden/user`);
  }

  public getWeiterleitenUsersByAktenEintragId(id: number): Observable<ExternalUserWithAccessDto[]> {
    return this.httpRestClient.get<ExternalUserWithAccessDto[]>(`${this.apiUrl}internal/ui/akte/users/${id}`);
  }

  /**
   * Retrieve a collection of all users that can be assigned a task associated with a digital folder.
   *
   * @param id The id of the digital folder
   * @return A collection of users that can be assigned a digital folder task
   */
  public getAufgabeUsersByAktenEintragId(id: number): Observable<ExternalUserWithAccessDto[]> {
    return this.httpRestClient.get<ExternalUserWithAccessDto[]>(`${this.apiUrl}internal/ui/akte/aufgabe/users/${id}`);
  }

  /**
   * @param id The id of the digital folder
   * @param task The task to store
   */
  public storeAufgabeForAktenEintrag(id: number, task: LightAufgabeDto): Observable<void> {
    return this.httpRestClient.post<void>(`${this.apiUrl}internal/ui/akte/aufgabe/store/${id}`, task);
  }

  public getAllAktenEintragBySchadenNummer(saSchadenNummer: number): Observable<AktenListeDto> {
    return this.httpRestClient.get<AktenListeDto>(`${this.apiUrl}internal/ui/akte/eintrag/sn/${saSchadenNummer}`);
  }

  public getAktenEintragById(id: number): Observable<AktenEintragDto> {
    return this.httpRestClient.get<AktenEintragDto>(`${this.apiUrl}internal/ui/akte/eintrag/${id}`);
  }

  public getSchadenmeldungAkte(
    token: string
  ): Observable<SchadenmeldungErgebnisDto> {
    return this.httpRestClient.post<SchadenmeldungErgebnisDto>(
      `${this.apiUrl}internal/schadenmeldung/result`, token
    );
  }

  public getFileStorageDownloadLink(key: string): HTMLAnchorElement {
    const link = document.createElement("a");
    link.href = `${this.apiUrl}download/store/${key}`;
    return link;
  }

  public mapTypeToTitle(typ: AktenTypDto): string {
    let translateKey;
    switch (typ) {
    case AktenTypDto.NOTIZ:
      translateKey = "akte.detail.typ.notiz";
      break;
    case AktenTypDto.SCHADENMELDUNG:
      translateKey = "akte.detail.typ.schaden.vorgang.neu";
      break;
    case AktenTypDto.MAKLERNACHRICHT:
      translateKey = "akte.detail.typ.notiz.makler";
      break;
    case AktenTypDto.BRIEF:
      translateKey = "akte.detail.typ.brief";
      break;
    case AktenTypDto.BRIEFENTWURF:
      translateKey = "akte.detail.typ.briefentwurf";
      break;
    case AktenTypDto.EMAIL:
      translateKey = "akte.detail.typ.email";
      break;
    case AktenTypDto.VERSICHERERNACHRICHT:
      translateKey = "akte.detail.typ.versicherernachricht";
      break;
    default:
      translateKey = "akte.detail.typ.notiz";
    }
    return this.translateService.instant(translateKey);
  }

  public mapRichtungTypToTitle(typ: AkteEintragTypRichtungDto): string {
    let translateKey: string;
    switch (typ) {
    case AkteEintragTypRichtungDto.AUSGEHEND:
      translateKey = "akte.detail.richtungTyp.ausgehend";
      break;
    case AkteEintragTypRichtungDto.EINGEHEND:
      translateKey = "akte.detail.richtungTyp.eingehend";
      break;
    default:
      return "";
    }
    return this.translateService.instant(translateKey);
  }

  public mapTypeToIconString(typ: AktenTypDto): string {
    let iconKey: string;
    switch (typ) {
    case AktenTypDto.NOTIZ:
      iconKey = "sticky_note_2";
      break;
    case AktenTypDto.MAKLERNACHRICHT:
      iconKey = "person";
      break;
    case AktenTypDto.BRIEF:
      iconKey = "email";
      break;
    case AktenTypDto.BRIEFENTWURF:
      iconKey = "drafts_outline";
      break;
    case AktenTypDto.EMAIL:
      iconKey = "alternate_email";
      break;
    case AktenTypDto.VERSICHERERNACHRICHT:
      iconKey = "business";
      break;
    case AktenTypDto.SCHADENMELDUNG:
      iconKey = "flash_on";
      break;
    default:
      iconKey = "sticky_note_2";
    }
    return iconKey;
  }

  public getReadPermissionOnSchlagworte(): Observable<boolean> {
    return this.httpRestClient.get<boolean>(`${this.apiUrl}internal/ui/akte/schlagworte/permission/read`);
  }

  /**
   * Get the permissions related to a digital folder.
   *
   * @param akteRefs An optional collection of typed references
   * @return An object that wraps a collection of digital folder permissions
   */
  public getWritePermissionsOnRef(akteRefs: AkteRefDto[]): Observable<AktePermissionsDto> {
    return this.httpRestClient.post<AktePermissionsDto>(
      `${this.apiUrl}internal/ui/akte/eintrag/permission/write`, akteRefs || []
    );
  }

  public getAkteEntriesByFilter(request: AkteListePagedRequestDto): Observable<AktenListeDto> {
    return this.httpRestClient.post<AktenListeDto>(`${this.apiUrl}internal/ui/akte/liste`, request);
  }

  public deleteAkteEntry(id: number, version: number): Observable<void> {
    return this.httpRestClient.request<void>("delete", `${this.apiUrl}internal/ui/akte/eintrag/${id}`, { body: { version } });
  }

  public getAkteFilter(): Observable<AkteListePagedRequestDto> {
    return this.httpRestClient.get<AkteListePagedRequestDto>(`${this.apiUrl}internal/ui/akte/liste/filter`);
  }

  public forwardAkteNotiz(aktenEintragId: number, notiz: NotizErstellenDto, files: File[]): Observable<void> {
    const formData = FormDataBuilder.newInstanceForNotiz(notiz, files).build();
    return this.httpRestClient.post<void>(`${this.akteUrl}/notiz/teilen/${aktenEintragId}`, formData);
  }

  // NOTIZ ERSTELLEN
  public sendNewAkteNotiz(notiz: NotizErstellenDto, files: File[]): Observable<void> {
    // we send date and time in the form
    // notiz.edit.datum = this.createUtcDateAndTimeFrom(notiz.edit.datum);
    const formData = FormDataBuilder.newInstanceForNotiz(notiz, files).build();
    return this.httpRestClient.post<void>(`${this.apiUrl}internal/ui/akte/neu/notiz`, formData);
  }

  // NOTIZ BEARBEITEN
  public sendEditAkteNotiz(id: number, notiz: AkteEditNotizDto, files: File[]): Observable<AktenEintragDto> {
    return this.sendEdit(id, notiz, files);
  }

  // EMAIL ERSTELLEN
  public sendNewAkteEmail(email: EmailErstellenDto, files: File[]): Observable<AktenAnhangDto> {
    email.edit.datum = this.createUtcDateAndTimeFrom(email.edit.datum);
    const formData = FormDataBuilder.newInstanceForEmail(email, files).build();
    return this.httpRestClient.post<AktenAnhangDto>(`${this.apiUrl}internal/ui/akte/neu/email/erstellen`, formData);
  }

  // EMAIL BEARBEITEN
  public sendEditAkteEmail(id: number, email: AkteEditEmailDto, files: File[]): Observable<AktenEintragDto> {
    return this.sendEdit(id, email, files);
  }

  /**
   * EMAILS ABLEGEN / EML FILES HOCHLADEN
   */
  public sendAkteEmailAblegen(email: EmailAblegenDto, files: File[]): Observable<void> {
    email.edit.datum = this.createUtcDateAndTimeFrom(email.edit.datum);
    const formData = FormDataBuilder.newInstanceForEmail(email, files).build();
    return this.httpRestClient.post<void>(`${this.apiUrl}internal/ui/akte/neu/email/ablegen`, formData);
  }

  /**
   * MAKLERNACHRICHT ERSTELLEN
   */
  public sendNewAkteMaklernachricht(dto: MaklernachrichtErstellenDto, files: File[]): Observable<void> {
    const formData = FormDataBuilder.newInstanceForMaklerNachricht(dto, files).build();
    return this.httpRestClient.post<void>(`${this.apiUrl}internal/ui/akte/neu/maklernachricht`, formData);
  }

  /**
   * SCHADENMELDUNG ERSTELLEN
   */
  public sendNewSchadenmeldung(dto: MaklernachrichtErstellenDto, files: File[]): Observable<void> {
    const formData = FormDataBuilder.newInstanceForMaklerNachricht(dto, files).build();
    return this.httpRestClient.post<void>(`${this.apiUrl}internal/ui/akte/neu/schadenmeldung`, formData);
  }

  /**
   * MAKLERNACHRICHT BEANWORTEN
   */
  public replyAkteMaklernachricht(id: number, maklernachrichtReply: MaklernachrichtErstellenDto, files: File[]): Observable<void> {
    const formData = FormDataBuilder.newInstanceForMaklerNachricht(maklernachrichtReply, files).build();
    return this.httpRestClient.post<void>(`${this.apiUrl}internal/ui/akte/neu/maklernachricht/${id}`, formData);
  }

  /**
   * MAKLERNACHRICHT BEARBEITEN
   */
  public sendEditAkteMaklernachricht(id: number, notiz: AbstractAkteEditDto, files: File[]): Observable<AktenEintragDto> {
    return this.sendEdit(id, notiz, files);
  }

  /**
   * Renames a digital folder attachment
   *
   * @param eintragId The id of the digital folder the attachment is associated with
   * @param editDto The object containing the attachment's update information
   */
  public renameAnhangForAktenEintrag(eintragId: number, editDto: AktenAnhangEditDto): Observable<void> {
    const formData: FormData =  FormDataBuilder.newInstanceForAktenAnhang(editDto).build();
    return this.httpRestClient.post<void>(`${this.akteUrl}/eintrag/${eintragId}/rename/anhang`, formData);
  }

  /**
   * Saves a newly created or already existing annotations for an attachment's page
   *
   * @param anhangId The attachment's id
   * @param seite The attachment's page
   * @param annotations The annotations object (JSON)
   * */
  public sendEditAktenEintragAnnotations(anhangId: number, seite: number, annotations: string): Observable<void> {
    const formData: FormData = FormDataBuilder.newInstanceForAktenEintragAnnotations(annotations).build();
    return this.httpRestClient.post<void>(`${this.akteUrl}/eintrag/anhang/${anhangId}/${seite}`, formData);
  }

  public getMitarbeiterAdresseByAktenTyp(maklernachrichtId: number, akteRefs: AkteRefDto[]): Observable<MaklernachrichtEmpfaengerDto[]> {
    const adressenReq: MaklernachrichtAdressenRequestDto = {
      akteRefs,
      maklernachrichtId
    };
    return this.httpRestClient.post<MaklernachrichtEmpfaengerDto[]>(`${this.apiUrl}internal/ui/akte/neu/maklernachricht/adressen`, adressenReq);
  }

  public getSchlagworteByAktenTyp(akte: AkteRefDto[]): Observable<string[]> {
    return this.httpRestClient.post<string[]>(`${this.apiUrl}internal/ui/akte/schlagworte`, akte);
  }

  public hasTenantSchlagworte(): Observable<boolean> {
    return this.httpRestClient.get<boolean>(`${this.apiUrl}internal/ui/akte/schlagworte/hasTenantSchlagworte`);
  }

  public getSchlagworteForFilter(akte: AkteRefDto[]): Observable<string[]> {
    return this.httpRestClient.post<string[]>(`${this.apiUrl}internal/ui/akte/schlagworte/filter`, akte);
  }

  public forwardLight(aktenEintrag: AktenEintragDto): Observable<Blob> {
    const request: AkteCreateForwardLightDto = {
      akteEintragId: aktenEintrag.id
    };
    return this.httpRestClient.post(`${this.apiUrl}internal/ui/akte/teilen`, request, {
      responseType: "blob"
    });
  }

  public getNotizBuilder(): NotizBuilder {
    return new NotizBuilder();
  }

  /**
   * Creates a complete date and time in UTC format from a complete date object.
   *
   * @param date A complete date in the format YYYY-MM-DD
   * @return A complete date plus hours, minutes and seconds in the format YYYY-MM-DD:hh:mm:ssZ
   */
  public createUtcDateAndTimeFrom(date: string | Date): string {
    const today = moment();
    const inputDate = moment(date);
    return moment(inputDate).isSame(today, "day")
      ? today.toISOString()
      : inputDate.startOf("day").toISOString();
  }

  private sendEdit(id: number, dto: AbstractAkteEditDto, files: File[]) {
    const formData = FormDataBuilder.newInstanceForAktenEintrag(dto, files).build();
    return this.httpRestClient.post<AktenEintragDto>(`${this.apiUrl}internal/ui/akte/eintrag/${id}`, formData);
  }
}


