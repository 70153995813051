import { Component, Input, OnInit } from "@angular/core";
import { FieldConfigInputAreaDto } from "connect-frontend-service";
import { UntypedFormGroup } from "@angular/forms";
import { EditorSettingsService } from "connect-frontend-components/editor";

@Component({
  selector: "lib-field-input-area",
  templateUrl: "./field-input-area.component.html",
  styleUrls: ["./field-input-area.component.scss"]
})
export class FieldInputAreaComponent implements OnInit {

  @Input()
  public config: FieldConfigInputAreaDto;

  @Input()
  public formGroup: UntypedFormGroup;

  public isEditorConfigCompleted: boolean;
  public editorSettings;
  public editorToolbar;


  constructor(
    private editorService: EditorSettingsService
  ) { }

  ngOnInit(): void {

    if (this.editorService !== null) {
      this.editorService.isConfigCompleted.subscribe((loaded: boolean) => {
        if (loaded) {
          this.editorSettings = this.editorService.settings;
          this.editorToolbar = this.editorService.toolbar;
          this.isEditorConfigCompleted = true;
        }
      });
    }

  }


}
