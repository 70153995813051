import { Injectable } from "@angular/core";

import { PreventNavigateComponent } from "./prevent-navigate.component";

@Injectable()
export class PreventNavigateGuard  {
  canDeactivate(component: PreventNavigateComponent): boolean {

    if (!component.canDeactivate()) {
      if (confirm("You have unsaved changes! If you leave, your changes will be lost.")) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
